import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { v4 } from "uuid";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ListArrow from "../images/list-arrow.svg"

import {
  Section,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
  Badge,
} from "../components/section";

import WidthIcon from "../images/width.svg";
import HeightIcon from "../images/height.svg";
import LenthIcon from "../images/lenth.svg";
import PhoneIcon from "../components/Icons/PhoneIcon";
import QouteIcon from "../components/Icons/QouteIcon";
import DefaultButton from "../components/button/DefaultButton";
import DefaultOutlineButton from "../components/button/DefaultOutlineButton";
import ColorBoard from "../components/ColorBoard";
import BreakpointUp from "../components/Media/BreakpointUp";
import BreakpointDown from "../components/Media/BreakpointDown";
import RelatedProductCarousel from "../components/RelatedProductCarousel";
import QuotePopup from "../components/QuotePopup";

import generateHTML from "../utils/generateHTML";
import Content, { HTMLContent } from "../components/Content";

const Card = styled.div`
  display: flex;
  align-items: center;
  margin: 20px -15px 0 -15px;
  flex-direction: column;
  ${BreakpointUp.lg`		
		flex-direction: inherit;
		margin: 20px -30px 0 -30px;
	`}
  ${BreakpointUp.xl`		
		margin: 30px -50px 0 -50px;
	`}
	.badge {
    ${BreakpointUp.lg`					
			left:30px;
		`}
    ${BreakpointUp.xl`		
			left:50px;
		`}
  }
`;
const CardHeading = styled.div`
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  ${BreakpointUp.md`		
		text-align:left;
	`}
`;
const CardTitle = styled.h1`
  margin: 0 0 5px;
  color: #002337;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  letter-spacing: -0.5px;
`;
const CardFigure = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px 15px 15px;
  ${BreakpointUp.lg`		
		padding: 0 30px;
	`}
  ${BreakpointUp.xl`		
		padding: 0 50px;
	`}
	& .gatsby-image-wrapper {
    height: 100%;
  }
`;
const CardContent = styled.div`
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`		
		padding: 0 30px;
	`}
  ${BreakpointUp.xl`		
		padding: 0 50px;
	`}
`;
// const CardGrid = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -5px;
//   margin-left: -5px;
// `;
// const CardGridLeft = styled.div`
//   position: relative;
//   width: 100%;
//   min-height: 1px;
//   padding-right: 5px;
//   padding-left: 5px;
//   ${BreakpointUp.lg`
// 		flex: 0 0 75%;
// 		max-width: 75%;
// 	`}
// `;
// const CardGridRight = styled.div`
//   position: relative;
//   width: 100%;
//   min-height: 1px;
//   padding-right: 5px;
//   padding-left: 5px;
//   ${BreakpointUp.lg`
// 		flex: 0 0 25%;
// 		max-width: 25%;
// 	`}
// `;
const CardOption = styled.ul`
  margin: 0 -15px;
  list-style: none;
  padding: 0;
  ${BreakpointDown.sm`
		font-size:14px;
	`}
`;
const CardOptionItem = styled.li`
  display: inline-block;
  font-size: 12px;
  margin: 0 15px;
  color: #6c7780;
  font-weight: 500;
  position: relative;
  &:before {
    display: inline-block;
    content: "";
    width: 6px;
    height: 6px;
    background-color: #676b79;
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
`;
// const PriceBlock = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   ${BreakpointUp.lg`
// 		flex-direction: column;
// 	`}
//   & small {
//     display: block;
//     font-size: 16px;
//     color: #231f20;
//     font-weight: 700;
//     line-height: 26px;
//     text-align: right;
//   }
// `;
// const TotalPrice = styled.div`
//   margin-bottom: 15px;
//   & strong {
//     display: block;
//     font-family: "Fira Sans", serif;
//     font-weight: 700;
//     color: #0a4e82;
//     font-size: 34px;
//     line-height: 40px;
//     text-align: right;
//   }
// `;

const ButtonToolbar = styled.div`
  display: flex;
  margin: 0 -10px 20px -10px;
  ${BreakpointDown.sm`
	display:block;
	`}
  & a, & button {
    margin: 0 10px;
    ${BreakpointDown.sm`
      width: calc(100% - 20px);
			display:block;
		`}
    + a, + button {
      ${BreakpointDown.sm`
				margin-top:15px
			`}
    }
    & .btn, & button {
      ${BreakpointDown.sm`
				display:block;
				width:100%;
			`}
    }
  }
  .quote {
    &:hover {
      cursor: pointer;
    }
  }
`;
const HelpBlock = styled.p`
  color: #999;
  margin: 0;
  line-height: 20px;
  font-size: 11px;
  ${BreakpointUp.lg`
		font-size: 12px;
	`}
`;
const ProductGrid = styled.div`
  position: relative;
`;
const ProductId = styled.div`
  display: inline-block;
  background-color: #002337;
  color: #fff;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
const ProductSize = styled.ul`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  list-style: none;
  ${BreakpointUp.lg`	
		width: 100%;
		max-width: 90px;
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		right: 0;
		z-index: 1;
		flex-direction: column;		
	`}
`;
const ProductItemSize = styled.li`
  background-color: #fff;
  padding: 9px 15px;
  border-top: none;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0;
  ${BreakpointUp.lg`
		padding:15px;
	`}
  + li {
    border-left: 1px solid #e5e5e5;
    border-top: none;
    ${BreakpointUp.lg`
			border-top:1px solid #E5E5E5;			
			border-left:none;
		`}
  }
  ${BreakpointUp.sm`
		display: flex;
		align-items: center;
	`}
  ${BreakpointUp.lg`
		border-left:none;
		flex-direction: column;
	`}	
	.icon {
    height: 30px;
    + .text {
      ${BreakpointUp.sm`
				margin-left:8px;
			`}
    }
    + .text {
      ${BreakpointUp.lg`
					margin-left:0;
				`}
    }
  }
  .text {
    font-size: 12px;
    color: #6c7780;
    font-weight: 500;
    white-space: nowrap;
    line-height: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    ${BreakpointUp.sm`
			display:block;
		`}
    b {
      display: block;
      font-size: 18px;
      font-weight: 700;
      color: #231f20;
      line-height: 20px;
      margin-left: 5px;
      ${BreakpointUp.sm`
				margin-left:0;
			`}
      ${BreakpointUp.lg`
				margin-left:5px;
			`}
    }
    ${BreakpointUp.lg`
			display: flex;
    		align-items: center;
		`}
  }
`;

const ProductBottomGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  margin: 0;
  list-style: none;
  padding: 35px 15px;
  ${BreakpointUp.md`	
    padding: 35px 45px;
	`}
`;
const ProductBottomItem = styled.li`
  position: relative;
  margin-bottom: 0;
  width: 100%;  
  ${BreakpointUp.sm`	
		display: flex;
		flex-direction: column;
	`}
  &:first-child{
    & ul{
      list-style: none;
      margin:0 0 30px;
      padding:0;
      & li {
        padding-left:20px;
        vertical-align: text-top;
        color:#231F20;
        font-weight:400;
        margin:0;
        line-height: 28px;
        + li{
          margin-top:10px;
        }
        &:before {
          content: url(${ListArrow});
          display: inline-block;
          margin-left: -20px;
          width: 20px;
          vertical-align: top;
        }
      }
    }
  }
  
  + li {
    border-top: 1px solid #e5e5e5;
    margin-top: 40px;
    padding-top: 40px;
  }
  ${BreakpointUp.lg`		
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	`}
  h2 {
    font-size: 22px;
    line-height: 32px;
    ${BreakpointUp.lg`
			font-size: 24px;
			line-height: 34px;
		`}
  }
  

`;
const StickyFooter = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    position: fixed;
    background: white;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    padding: 10px;
    border-top: 1px solid #eee;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    & .quote {
      display: inline-block;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  render() {
    const { data } = this.props;
    const { isVisibleQuote } = this.state;
    const productData = data.contentfulProduct;
    const productList = data.allContentfulProduct;
    const PostContent = HTMLContent || Content;
    const commonMetaTitle =
      productData.productName +
      " - Shop " +
      productData.productName +
      " Online";
    const commonMetaDescription =    
      productData.productName +
      " - Perfectly designed " +
      productData.productName +
      " to fit your exact need. Check out the building details and Order your building today.";

      const schema = {
        "@context": "https://schema.org",
        "@type": "Product",
        name: productData.productName,
        image: productData.featuresImage != null && productData.featuresImage[0]['file']['url'],
        description:productData.productDescription != null && productData.productDescription.productDescription,
        mpn: "MPN",
        SKU: productData.productSku,
        brand: {
          "@type": "Thing",
          name: "Mr Carports",
        },
        offers: {
          "@type": "Offer",
          url: `"https://www.mrcarports.com${productData.url}`,
          priceValidUntil: `${new Date().getFullYear()}-12-31`,
          priceCurrency: "USD",
          price: "00",
          itemCondition: "NewCondition",
          availability: "InStock",
          seller: {
            "@type": "Organization",
            name: "Mr Carports",
          },
        },
        review: {
          "@type": "Review",
          reviewRating: {
            "@type": "Rating",
            ratingValue: "5",
            bestRating: "5",
          },
          author: {
            "@type": "Person",
            name: "CTC",
          },
        },
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "4.2",
          reviewCount: "30",
        },
        category: `<span>${productData.productCategory.name}</span>`,
        material: "14 GA Steel",
        width: {
          "@type": "QuantitativeValue",
          value: productData.width,
          unitcode: "FOT",
        },
        depth: {
          "@type": "QuantitativeValue",
          value: productData.length,
          unitcode: "FOT",
        },
      }
    return (
      <Layout>
        <SEO
          title={
            productData.metaTitle ? productData.metaTitle : commonMetaTitle
          }
          description={commonMetaDescription}
          schemaMarkup={schema}
        />
        <Section pt="140px" pb="300px" xpt="110px" xpb="60px" bg="#F4F8FC">
          <ProductGrid>
            <div className="container">
              <BreadCrumb mb="10px">
                <Link to="/">Home</Link>/
                <Link to={productData.productCategory.link}>
                  {productData.productCategory.name}
                </Link>
                /<span>{productData.productName}</span>
              </BreadCrumb>
              <Card className="card">
                <CardFigure className="card-img">
                  <Badge
                    className="badge"
                    top="20px"
                    color="#2F9800"
                    bgColor="#d5eacc"
                  >
                    {productData.popularLabel}
                  </Badge>
                  <Img fluid={productData.image.fluid} />
                  <ProductSize className="measurement">
                    <ProductItemSize>
                      <span className="icon">
                        <img src={WidthIcon} alt="width" />
                      </span>
                      <span className="text">
                        Width <b>{productData.width}</b>
                      </span>
                    </ProductItemSize>
                    <ProductItemSize>
                      <span className="icon">
                        <img src={LenthIcon} alt="length" />
                      </span>
                      <span className="text">
                        Lenth <b>{productData.length}</b>
                      </span>
                    </ProductItemSize>
                    <ProductItemSize>
                      <span className="icon">
                        <img src={HeightIcon} alt="height" />
                      </span>
                      <span className="text">
                        Height <b>{productData.height}</b>
                      </span>
                    </ProductItemSize>
                  </ProductSize>
                </CardFigure>
                <CardContent className="card-body">
                  <CardHeading>
                    <ProductId>SKU - {productData.productSku}</ProductId>
                    <CardTitle>{productData.productName}</CardTitle>
                    {productData.featuresImage && (
                        <CardOption>
                          {productData.featuresImage.map((item) => {
                            return (
                              <CardOptionItem key={v4()}>{item.title}</CardOptionItem>
                            );
                          })}
                        </CardOption>
                      )}
                  </CardHeading>
                  {/* <CardGrid>
                    <CardGridLeft>
                    </CardGridLeft>
                    <CardGridRight>
                      <PriceBlock>
                        <TotalPrice>
                          <strong>${productData.totalPrice}</strong>
                          <small>Starting Price</small>
                        </TotalPrice>
                      </PriceBlock>
                    </CardGridRight>
                  </CardGrid> */}
                  <ButtonToolbar>
                    <a href="tel:8773505464" rel="noreferrer">
                      <DefaultButton
                        icon={<PhoneIcon />}
                        aria-label="Call Us Now"
                        text="Call Us Now"
                      />
                    </a>
                    <button type="button" aria-label="quote"
                    onClick={this.showQuote}
                    onKeyDown={this.showQuote}
                    >
                    <DefaultOutlineButton icon={<QouteIcon />} text="Get a Quote" />
                  </button>
                  </ButtonToolbar>
                  <HelpBlock>{productData.priceDescription}</HelpBlock>
                </CardContent>
              </Card>
            </div>
          </ProductGrid>
        </Section>
        <Section
          pb="90px"
          xpt="60px"
          xpb="60px"
          mt="-240px"
          xmt="0"
          bg="transparent"
        >
          <div className="container">
            <ProductBottomGrid>
              <ProductBottomItem>
                <h2>Description</h2>
                {productData.productDescription && (
                  <PostContent
                    content={generateHTML(
                      productData.productDescription.childMarkdownRemark.html
                    )}
                  />
                )}
              </ProductBottomItem>
              <ProductBottomItem>
                <h2>Available Colors</h2>
                <ColorBoard />
              </ProductBottomItem>
            </ProductBottomGrid>
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
          <div className="container">
            <SectionTitle maxWidth="550px">
              {productData.secondSectionTitle}
            </SectionTitle>
            <SectionDescription>
              {productData.secondSectionDescription.secondSectionDescription}
            </SectionDescription>
            <RelatedProductCarousel
              data={productList}
              showQuote={this.showQuote}
            />
          </div>
        </Section>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
        <StickyFooter className="product-footer">
          <a href="tel:8773505464">
            <DefaultOutlineButton
              icon={<PhoneIcon />}
              aria-label="Call Us Now"
              text="Call Us Now"
            />
          </a>
          <div
            role="button"
            tabIndex={0}
            className="quote"
            onClick={this.showQuote}
            onKeyDown={this.showQuote}
          >
            <DefaultButton icon={<QouteIcon />} text="Get a Quote" />
          </div>
        </StickyFooter>
      </Layout>
    );
  }
}

export default Product;

export const pageQuery = graphql`
  query productQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      id
      productSku
      productName
      popularLabel
      image {
        title
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      width
      height
      length
      totalPrice
      priceDescription
      featuresImage {
        title
        file {
          url
        }
      }
      productRoof {
        roofName
      }
      popularLabel
      url
      productCategory {
        name
        link
      }
      productDescription {
        childMarkdownRemark {
          html
        }
        productDescription
      }
      secondSectionTitle
      secondSectionDescription {
        secondSectionDescription
      }
    }
    allContentfulProduct(sort: { fields: totalPrice }) {
      edges {
        node {
          productSku
          productName
          image {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          popularLabel
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
          popularLabel
          url
          productCategory {
            name
          }
        }
      }
    }
  }
`;
